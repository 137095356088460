import React, { useEffect, useState } from 'react';
import { fetchProjects } from '../services/contentfulService'; 
import './Projects.css'; // Include styling

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const getProjects = async () => {
      const data = await fetchProjects({ limit: 2 }); // Fetch only two most recent projects
      setProjects(data);
    };
    getProjects();
  }, []);

  return (
    <section id="projects" className="projects-section">
      <h2 className="section-title">Projects</h2>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div className="card" key={index}>
            <div className="content">
              <div className="front">
                <div className="front-content">
                  <small className="badge">{project.technologies}</small>
                  <div className="description">
                    <div className="title">
                      <p><strong>{project.title}</strong></p>
                    </div>
                    <p className="card-footer">{project.description}</p>
                  </div>
                </div>
              </div>
              <div className="back">
                <div className="back-content">
                  <strong>{project.title}</strong>
                  <a href={project.link} target="_blank" rel="noopener noreferrer" className="view-project-btn">
                    View Project
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
