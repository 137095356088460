import { createClient } from 'contentful';

const client = createClient({
  space: 'jtwgky6mdwce', // Replace with your space ID
  accessToken: '8a97vDwPF1b3NdJ2IywopbAlYP9UL38f3RjxWM5TM90' // Replace with your access token
});

export const fetchProjects = async (options = {}) => {
  const query = {
    content_type: 'project', // Ensure this matches your content type ID
    order: '-sys.createdAt', // Fetch the most recent
    ...options
  };

  try {
    const response = await client.getEntries(query);
    return response.items.map((item) => ({
      title: item.fields.title, // Ensure this field name matches your model
      description: item.fields.description, // Adjust to your field names
      technologies: item.fields.technologies, // Adjust to your field names
      link: item.fields.link, // Adjust to your field names
      image: item.fields.image?.fields?.file?.url, // Ensure proper field name
      slug: item.fields.slug // Ensure proper field name
    }));
  } catch (error) {
    console.error('Error fetching projects:', error);
    return [];
  }
};

export const fetchBlogs = async (options = {}) => {
  try {
    const response = await client.getEntries({
      content_type: 'externalBlogPost', // Replace with your content type ID for blogs
      order: '-sys.createdAt',   // Fetch by recent first
      limit: options.limit || 2, // Limit to 2 blogs by default
    });

    if (response.items.length === 0) {
      return [];
    }

    // Map over the response to extract the necessary fields
    return response.items.map((item) => ({
      title: item.fields.title || 'Untitled Blog',
      description: item.fields.description || 'No Description Available', // Description for the blog
      link: item.fields.link || '#',  // Assuming you're storing the blog link in a field called 'link'
    }));
  } catch (error) {
    console.error('Error fetching blogs:', error);
    return [];
  }
};
