import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './sections/Hero';
import Skills from './sections/Skills';
import Projects from './sections/Projects';
import Blog from './sections/Blog';
import Contact from './sections/Contact';
import Footer from './components/Footer';
import './App.css';
import './GlobalStyles.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        {/* This is the main route */}
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Skills />
              <Projects />
              <Blog />
              <Contact />
            </>
          }
        />
        {/* Specific projects route */}
        <Route path="/projects" element={<Projects />} />
        {/* Specific blog routes */}
        <Route path="/blogs" element={<Blog />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
