import React from 'react';
import './Footer.css'; // Your CSS file
import logo from './logo.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
      <img src={logo} alt="Logo" className="footer-logo" />{/* Update the path accordingly */}
        <p>Designed and Developed by DevRex</p>
        <div className="social-footer-icons">
          <a href="https://linkedin.com/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://github.com/" aria-label="GitHub" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github"></i>
          </a>
          <a href="https://instagram.com/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://youtube.com/" aria-label="YouTube" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
