import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false); // State to track if message is sent

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);

  const handleSubmit = () => {
    // Here you would typically send the form data to a server
    // using fetch or axios. For now, we'll just simulate sending
    // and update the isSent state.
    setIsSent(true);
    setTimeout(() => {
      setIsSent(false); // Reset after a short delay
    }, 3000); // 3 seconds
  };

  const handleReset = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <section id="contact" className="contact-section">
      <div className="form-container">
        <div className="form">
          <span className="heading">Get in touch</span>
          <input
            placeholder="Name"
            type="text"
            className="input"
            value={name}
            onChange={handleNameChange}
          />
          <input
            placeholder="Email"
            id="mail"
            type="email"
            className="input"
            value={email}
            onChange={handleEmailChange}
          />
          <textarea
            placeholder="Say Hello"
            rows="10"
            cols="30"
            id="message"
            name="message"
            className="textarea"
            value={message}
            onChange={handleMessageChange}
          ></textarea>
          <div className="button-container">
            <div
              className="send-button"
              onClick={handleSubmit}
              disabled={isSent} // Disable send button while sending
            >
              {isSent ? 'Sending...' : 'Send'}
            </div>
            <div className="reset-button-container">
              <div
                id="reset-btn"
                className="reset-button"
                onClick={handleReset}
              >
                Reset
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
