import React, { useEffect, useState } from 'react';
import { fetchBlogs } from '../services/contentfulService'; 
import './Blog.css'; // Include styling

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const getBlogs = async () => {
      const data = await fetchBlogs({ limit: 2 }); // Limit the blogs to 2 most recent
      setBlogs(data);
    };
    getBlogs();
  }, []);

  return (
    <section id="blogs" className="blogs-section">
      <h2 className="section-title">Blogs</h2>
      <div className="blogs-container">
        {blogs.map((blog, index) => (
          <div className="card" key={index}>
            <div className="content">
              <div className="front">
                <div className="front-content">
                  <small className="badge">{blog.category || "Blog"}</small>
                  <div className="description">
                    <div className="title">
                      <p><strong>{blog.title}</strong></p>
                    </div>
                    <p className="card-footer">{blog.description}</p>
                  </div>
                </div>
              </div>
              <div className="back">
                <div className="back-content">
                  <strong>{blog.title}</strong>
                  {/* Redirect to external blog link */}
                  <a href={blog.link} target="_blank" rel="noopener noreferrer" className="view-project-btn">
                    Read Blog
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="view-more">
        <a href="/blogs" className="view-more-btn">View More Blogs</a>
      </div>
    </section>
  );
};

export default Blogs;
